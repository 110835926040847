import { useHasMounted } from 'hooks'
import dynamic from 'next/dynamic'
import { FC, useState } from 'react'
import { Box } from 'ui-v2/components/Box'
import { UnstyledButton } from 'ui-v2/components/Button'
import { toggleCx } from './HamburgerMenuToggle.css'

const HamburgerMenuNav = dynamic(() =>
  import('./HamburgerMenuNav').then((mod) => mod.HamburgerMenuNav)
)
export interface HamburgerMenuToggleProps {}

export const HamburgerMenuToggle: FC<HamburgerMenuToggleProps> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const hasMounted = useHasMounted()
  const toggleModal = () => setIsOpen((prev) => !prev)
  return (
    <Box>
      <UnstyledButton
        aria-label="Menu Toggle"
        onPress={toggleModal}
        className={({ isFocused, isFocusVisible }) =>
          toggleCx({ isFocusVisible: isFocused && isFocusVisible })
        }
      />
      {hasMounted && <HamburgerMenuNav isOpen={isOpen} />}
    </Box>
  )
}

import { FC } from 'react'
import { iconSprinkles } from 'vanilla-extract-config/sprinkles'
import { BaseIconWrapper } from './common/BaseIconWrapper'
import { SvgIconProps } from './types'

export const GlobeIcon: FC<SvgIconProps> = ({ ...props }) => (
  <BaseIconWrapper {...props} data-testid="globe-icon">
    <circle
      cx="8"
      cy="8"
      r="6.5"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
    />
    <path
      d="M8.5 2C8.5 2 10.5 5 10.5 8C10.5 11 8.5 14 8.5 14"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
      strokeLinecap="round"
    />
    <path
      d="M7.5 14C7.5 14 5.5 11 5.5 8C5.5 5 7.5 2 7.5 2"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
      strokeLinecap="round"
    />
    <path
      d="M2 8H14"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
      strokeLinecap="round"
    />
  </BaseIconWrapper>
)

import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Image, { StaticImageData } from 'next/image'
import BatteryStorage from 'public/images/all-system-go/battery-storage.png'
import Data from 'public/images/all-system-go/data.png'
import Forecasts from 'public/images/all-system-go/forecasts.png'
import { FC, useState } from 'react'
import { Box } from 'ui-v2/components/Box'
import { Flex } from 'ui-v2/components/Flex'
import { Grid } from 'ui-v2/components/Grid'
import { Text } from 'ui-v2/components/Text'
import { DemoModal } from '../DemoModal'
import { DemoModalToggle } from '../DemoModalToggle'
import {
  containerCx,
  featureImageCx,
  featureImageWrapperCx,
  featureTextWrapperCx,
  featureWrapperCx,
  featuresWrapperCx,
  headerWrapperCx,
  noWrapCx,
  subHeaderCx,
} from './AllSystemsGoSection.css'

export const d = {
  title: 'tensor-website-landing:all-systems-go.title',
  sub: 'tensor-website-landing:all-systems-go.sub',
  batteryStorageTitle: 'tensor-website-landing:all-systems-go.battery-storage-title',
  batteryStorageDescription: 'tensor-website-landing:all-systems-go.battery-storage-description',
  forecastsTitle: 'tensor-website-landing:all-systems-go.forecasts-title',
  forecastsDescription: 'tensor-website-landing:all-systems-go.forecasts-description',
  dataTitle: 'tensor-website-landing:all-systems-go.data-title',
  dataDescription: 'tensor-website-landing:all-systems-go.data-description',
  cta: 'tensor-website-landing:all-systems-go.cta',
  batteryStorageImgAlt: 'tensor-website-landing:seo.battery-storage-img-alt',
  forecastsImgAlt: 'tensor-website-landing:seo.forecasts-img-alt',
  dataImgAlt: 'tensor-website-landing:seo.data-img-alt',
}

type FeatureBlockProps = {
  title: string
  src: string | StaticImageData
  alt: string
  description: string
}

const FeatureBlock: FC<FeatureBlockProps> = ({ src, alt, title, description }) => (
  <Grid className={featureWrapperCx}>
    <Box className={featureImageWrapperCx}>
      <Image
        sizes="(max-width: 768px) 314px, 314px"
        className={featureImageCx}
        src={src}
        fill
        alt={alt}
      />
    </Box>
    <Flex className={featureTextWrapperCx}>
      <Text variant="labelLg" as="h3">
        {title}
      </Text>
      <Text variant="paragraphLg" color="colorsTextNeutralDefaultLowContrast">
        {description}
      </Text>
    </Flex>
  </Grid>
)

export const AllSystemsGoSection: FC = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const features: FeatureBlockProps[] = [
    {
      src: BatteryStorage,
      alt: t(d.batteryStorageImgAlt),
      title: t(d.batteryStorageTitle),
      description: t(d.batteryStorageDescription),
    },
    {
      src: Forecasts,
      alt: t(d.forecastsImgAlt),
      title: t(d.forecastsTitle),
      description: t(d.forecastsDescription),
    },
    {
      src: Data,
      alt: t(d.dataImgAlt),
      title: t(d.dataTitle),
      description: t(d.dataDescription),
    },
  ]

  return (
    <Grid as="section" className={containerCx}>
      <Grid className={headerWrapperCx}>
        <Text as="h2" variant="displaySm">
          <Trans
            i18nKey={d.title}
            components={{
              br: <br />,
              nowrap: <span className={noWrapCx} />,
            }}
          />
        </Text>
        <Text
          variant="paragraphXl"
          color="colorsTextNeutralDefaultLowContrast"
          className={subHeaderCx}
        >
          {t(d.sub)}
        </Text>
      </Grid>
      <Grid className={featuresWrapperCx}>
        {features.map((feature) => (
          <FeatureBlock key={feature.title} {...feature} />
        ))}
      </Grid>
      <Grid>
        <DemoModalToggle
          openModal={() => setIsModalOpen(true)}
          buttonText={t(d.cta)}
          color="primary"
          size="lg"
        />
        <DemoModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </Grid>
    </Grid>
  )
}

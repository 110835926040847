import "../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fsections%2Fsplash-section%2FSplashSection.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VUy26DMBC88xW%2BVEoOrszDYMilf1K54IIlAgQcSFXl3ytskxhDQi49ej07Mztr%2BT0TVJwEAr8OABnvmpL%2BJCBveXZwAMhpk4CetjsIP6PwWPSsb%2FfjRVPSlEEu2LFLQMoqwdqxLNhFQFryvDKrDc0yXuVQ1DbbcN4fnKvzrky40oRGJwBZyicT60nsUHDBYNfQlCWgqoeWNgbGl5h1T0d6gQPPRJGAGKHmcnhZOVAu1wPQlC5Cb%2BOxYDwvxHgmWsPQdYkSvjFjxVx3XPC6SkDLSip4z54E%2FkAvjPCMObSY6VdXl2fBZhSeryxOJMTDqiD3BvGcMpKUJfsWCYCeNQmRl63iWdzGandbUa3bVWZcMqekLwzoTXtepPRxZBmnoEtbxipAqwzszD35IW4ue6kwewN3JhzpqK53DLYwge8tMKHGaCXsugpidLneVJKTI4sh0gx6ERgtNIhGTNtYgcSPxrntPl700Ln1KCQL6%2FeBtyIOxnXOEkaafvZ3BOQfEpyGNy%2B3%2FMov4%2FmL8Pph40H4KNhKFQfIHsfo2nIZLlzaFlZcWg5IrBBGjzv1XP8AWRcUjD0GAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var containerCx = 'dtatqt0';
export var gradientCx = 'dtatqt9';
export var imageWrapperCx = 'dtatqta';
export var linesStyle = _7a468({defaultClassName:'dtatqt6',variantClassNames:{position:{left:'dtatqt7',right:'dtatqt8'}},defaultVariants:{},compoundVariants:[]});
export var noWrapCx = 'dtatqt2';
export var screenshotInnerWrapperCx = 'dtatqt5';
export var screenshotOuterWrapperCx = 'dtatqt4';
export var subCx = 'dtatqt3';
export var titleCx = 'dtatqt1';
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { Button, ButtonProps } from 'ui-v2/components/Button'

export interface DemoModalToggleProps extends Partial<Pick<ButtonProps, 'size' | 'color'>> {
  openModal: VoidFunction
  buttonText?: string
}

export const d = {
  defaultBtnText: 'tensor-website-common:cta.book-demo',
}

export const DemoModalToggle: FC<DemoModalToggleProps> = ({
  size = 'lg',
  color = 'neutral',
  openModal,
  buttonText,
}) => {
  const { t } = useTranslation()
  return (
    <Button variant="solid" color={color} size={size} onPress={openModal}>
      {buttonText ?? t(d.defaultBtnText)}
    </Button>
  )
}

import Trans from 'next-translate/Trans'
import { FC } from 'react'
import { Grid } from 'ui-v2/components/Grid'
import { Text } from 'ui-v2/components/Text'
import { containerCx, noWrapCx } from './ElectricityCompaniesSection.css'

export const ElectricityCompaniesSection: FC = () => (
  <Grid as="section" className={containerCx}>
    <Text variant="headingMd" color="colorsTextPrimaryDefaultLowContrast">
      <Trans
        i18nKey="tensor-website-landing:electricity-companies-title"
        components={{
          nowrap: <span className={noWrapCx} />,
        }}
      />
    </Text>
  </Grid>
)

import { LinkedinLink } from '@/components/LinkedinLink'
import { SystemStatusIndicator } from '@/components/SystemStatusIndicator'
import { TensorFullNameSmallLogo } from '@/components/logos/TensorFullNameSmallLogo'
import { useSystemStatus } from '@/contexts/SystemStatusContext'
import {
  footerContainerCx,
  footerCx,
  leftSectionCx,
  linkBlockCx,
  linkBlocksContainerCx,
  linkCx,
  linksBlockListCx,
  listItemStyle,
} from '@/layouts/Footer.css'
import { TERMS_OF_SERVICE, getDocsLink } from 'links'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import { FC } from 'react'
import {
  BATTERY_STORAGE_OPTIMIZATION_URL,
  CAREERS_URL,
  COMPANY_URL,
  CONTACT_URL,
  FEATURES_DEVELOPMENT_URL,
  FEATURES_MANAGEMENT_URL,
  FEATURES_OPERATIONS_URL,
  FIP_URL,
  INSIGHTS_URL,
  PRESS_URL,
  PRICING_URL,
} from 'src/assets/links'
import { Box } from 'ui-v2/components/Box'
import { Flex } from 'ui-v2/components/Flex'
import { Grid } from 'ui-v2/components/Grid'
import { Text } from 'ui-v2/components/Text'

export interface FooterProps {}

type Link = {
  text: string
  href: string
  type: 'anchor' | 'link'
}

type LinksBlockProps = {
  title: string
  links: Link[]
}

export const d = {
  about: 'tensor-website-common:footer.about',

  product: 'tensor-website-common:footer.product',
  development: 'tensor-website-common:footer.development',
  aggregation: 'tensor-website-common:footer.aggregation',
  assetManagement: 'tensor-website-common:footer.asset-management',
  pvStorage: 'tensor-website-common:footer.pv-storage',
  pricing: 'tensor-website-common:footer.pricing',
  documentation: 'tensor-website-common:footer.documentation',

  company: 'tensor-website-common:footer.company',
  aboutUs: 'tensor-website-common:footer.about-us',
  contact: 'tensor-website-common:footer.contact',
  press: 'tensor-website-common:footer.press',
  careers: 'tensor-website-common:footer.careers',
  legal: 'tensor-website-common:footer.legal',

  insights: 'tensor-website-common:footer.insights',
  articles: 'tensor-website-common:footer.articles',
  fipCalculator: 'tensor-website-common:footer.fip-calculator',
}

const LinksBlock: FC<LinksBlockProps> = ({ title, links }) => (
  <Box className={linkBlockCx}>
    <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
      {title}
    </Text>
    <ul className={linksBlockListCx}>
      {links.map(({ text, href, type }) => (
        <li key={text} className={listItemStyle}>
          {type === 'link' ? (
            <a href={href} target="_blank" rel="noopener noreferrer" className={linkCx}>
              {text}
            </a>
          ) : (
            <NextLink href={href} className={linkCx}>
              {text}
            </NextLink>
          )}
        </li>
      ))}
    </ul>
  </Box>
)

export const Footer: FC<FooterProps> = () => {
  const { t, lang } = useTranslation()
  const { status } = useSystemStatus()

  const linkItems: LinksBlockProps[] = [
    {
      title: t(d.product),
      links: [
        { text: t(d.development), href: FEATURES_DEVELOPMENT_URL, type: 'anchor' },
        { text: t(d.aggregation), href: FEATURES_OPERATIONS_URL, type: 'anchor' },
        { text: t(d.assetManagement), href: FEATURES_MANAGEMENT_URL, type: 'anchor' },
        { text: t(d.pvStorage), href: BATTERY_STORAGE_OPTIMIZATION_URL, type: 'anchor' },
        { text: t(d.pricing), href: PRICING_URL, type: 'anchor' },
        {
          text: t(d.documentation),
          href: getDocsLink('/', lang),
          type: 'link',
        },
      ],
    },
    {
      title: t(d.company),
      links: [
        { text: t(d.aboutUs), href: COMPANY_URL, type: 'anchor' },
        { text: t(d.contact), href: CONTACT_URL, type: 'anchor' },
        { text: t(d.press), href: PRESS_URL, type: 'anchor' },
        {
          text: t(d.careers),
          href: CAREERS_URL,
          type: 'link',
        },
        { text: t(d.legal), href: getDocsLink(TERMS_OF_SERVICE, lang), type: 'link' },
      ],
    },
    {
      title: t(d.insights),
      links: [
        { text: t(d.articles), href: INSIGHTS_URL, type: 'anchor' },
        { text: t(d.fipCalculator), href: FIP_URL, type: 'anchor' },
      ],
    },
  ]

  return (
    <Grid className={footerContainerCx}>
      <Flex as="footer" className={footerCx}>
        <Box className={leftSectionCx}>
          <TensorFullNameSmallLogo />
          <SystemStatusIndicator status={status} />
          <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
            {t(d.about)}
          </Text>
          <LinkedinLink href="https://www.linkedin.com/company/tensorenergy/" />
        </Box>
        <Box className={linkBlocksContainerCx}>
          {linkItems.map((block) => (
            <LinksBlock key={block.title} title={block.title} links={block.links} />
          ))}
        </Box>
      </Flex>
    </Grid>
  )
}

import { iconCx } from '@/components/LinkedinLink.css'
import { FC } from 'react'
import { LinkedInIcon } from 'ui-v2/icons/LinkedInIcon'
import { IconSize } from 'vanilla-extract-config/sprinkles'

export interface LinkedinLinkProps {
  href: string
  size?: IconSize
}
export const LinkedinLink: FC<LinkedinLinkProps> = ({ href, size = 'md' }) => (
  <a
    aria-label="Linkedin"
    href={href}
    target="_blank"
    rel="noreferrer"
    className={iconCx}
    data-testid="linkedin-icon"
  >
    <LinkedInIcon size={size} />
  </a>
)

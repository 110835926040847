import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fnavs%2FTopNavButton.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81U3XKjIBS%2Bz1Nwk5k6s3Ri8%2BfSF9m7DgoaGuQ4gInpzr77DqhRjLbbu70TOHK%2Bn%2FPx%2FJYckkw3Mfq9Qiil2bnQUCtGkALFX90eaMb1sIbaSqH4sFFRxoQqCLpQ%2FYTx2%2FFQni78co7cYVZrA5qgCoSyXA8XYk2ZqM3kr%2Bsmel39WT13qF4eUAXVkAXV2%2F%2BIA0KWNxYznoGmVoDqew1wd98it%2F%2B8uql906tg9kRQSRucgbJc2YBd%2FFI1E4rK%2F8eEqSS9EVRowdxGQau2fCxfSPMYIQNSsAnsbfSVQJdbV9Jgc6IMrpPzeMMC6gdP%2FRGiFgxbXlaSWo4zkHWpDEG7pGpQnOsHFguGIESlKBQWlpeGoIz3Hk%2BGZMBznMfTae%2F6u%2BWJi%2BJkh%2FV7bazIb70v404LABbNVmn0XVsU%2B9qWj0D1xLPMwUEN6j6gTQVImDZveHDFT3%2FFbGEaFNJ%2FLUwXRsH5PBfdBWUXRmcUm3aChsaZb9x72lnaOZ6MU3LXdpKduGo%2Bi8vdajyrQpvuCoxoR5emBmRteZDdXHLfMhdSzl9grIYznz4029EZ7jjFAacUrIVy%2Bp5i3arR71qoCMKHLm2aKpODLkn76ZR%2BwvvN%2Bgc6riOkwbqN3Z7xInCYeaE7FB3O9qR9VnsL7qn2TfvVvEKS55ag%2FWa9BOyXR%2BZw%2FAX8KMESEAcAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var arrowCx = '_868crxc';
export var iconContainerCx = '_868crx7';
export var itemTitleCx = _7a468({defaultClassName:'_868crx8',variantClassNames:{isHovered:{true:'_868crx9'},isFocusVisible:{true:'_868crxa'}},defaultVariants:{},compoundVariants:[]});
export var linkButtonCx = _7a468({defaultClassName:'_868crx3',variantClassNames:{isTriggerActive:{true:'_868crx4'}},defaultVariants:{},compoundVariants:[]});
export var linkCx = '_868crx6';
export var menuItemsContainerCx = '_868crx5';
export var titleWithLinkCx = '_868crxb';
export var trapezoidCx = '_868crxd';
export var trapezoidWidthDiff = 'var(--_868crx0)';
export var triggerButtonCx = _7a468({defaultClassName:'_868crx1',variantClassNames:{isTriggerActive:{true:'_868crx2'}},defaultVariants:{},compoundVariants:[]});
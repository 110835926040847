import { useViewport } from '@/contexts/ViewportContext'
import dynamic from 'next/dynamic'
import { FC } from 'react'
import { Grid } from 'ui-v2/components/Grid'
import { HowItWorksHeading } from './HowItWorksHeading'
import { containerCx } from './HowItWorksSection.css'

const HowItWorksDesktop = dynamic(() => import('./HowItWorks').then((mod) => mod.HowItWorks))
const HowItWorksMobile = dynamic(() =>
  import('./HowItWorksMobile').then((mod) => mod.HowItWorksMobile)
)

export interface HowItWorksSectionProps {}
export const HowItWorksSection: FC<HowItWorksSectionProps> = () => {
  const { width } = useViewport()
  const isMobile = width > 0
  const isDesktop = width > 800
  return (
    <Grid as="section" className={containerCx}>
      <HowItWorksHeading />
      {isDesktop ? <HowItWorksDesktop /> : isMobile ? <HowItWorksMobile /> : null}
    </Grid>
  )
}
